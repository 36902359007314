body, html, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #2f2f2f; 
    overflow: hidden; /* Prevent scrolling */
}

.tap2earn-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 20px;
    color: white;
    text-align: center;
}

.header-icons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 20px;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-container p {
    margin-top: 5px;
    font-size: 14px;
}

.balance-container {
    font-size: 36px;
    margin-bottom: 30px;
    position: relative;
    top: -20px; /* Move the balance up */
}

.character-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    top: -40px; /* Move the button up */
}

.earn-button {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: linear-gradient(145deg, #ffcc00, #ff9900);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    transition: transform 0.2s;
}

.earn-button:focus {
    outline: none;
}

.earn-button:hover {
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.6);
}

.energy-container {
    font-size: 18px;
    margin: 20px 0;
    color: #f39c12;
}

.navigation-bar {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #1c1c1c;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
}

.nav-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-button:focus {
    outline: none;
}

.nav-button:hover {
    color: #f39c12;
}

.nav-button p {
    margin-top: 5px;
    font-size: 14px;
}

/* Animation for balance count */
.animate {
    animation: sparkle 0.5s ease-in-out;
}

@keyframes sparkle {
    0% {
        transform: scale(1);
        text-shadow: none;
    }
    50% {
        transform: scale(1.2);
        text-shadow: 0 0 10px #ffcc00, 0 0 20px #ff9900;
    }
    100% {
        transform: scale(1);
        text-shadow: none;
    }
}
